import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';
import ImageResize  from 'quill-image-resize';
Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);

function getQuillImageOptions() {

  let token = $('input[name="_token"').val();

  let imageOptions = {
    upload: file => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("_token", token);

        fetch(
          "/dashboard/admin/media/quill-upload",
          {
            method: "POST",
            body: formData
          }
        )
          .then(response => response.json() )
          .then(result => {

            let msgs = '';
            if(result.errors && result.errors.image.length) {
              
              $.each(result.errors.image, function(key, error) {
                msgs += ('<li>' + error + '</li>');
              });

              let options = {
                type : 'alert',
                style : 'danger',
                heading : 'Upload Failed',
                text : '<ul>' + msgs + '</ul>'
              }
              customAlert(options);
              $('.image-uploading').remove();
            }
            else {
              resolve(result);
            }
            
          })
          .catch(error => {
          
            reject("Upload failed");
            console.error("Error:", error);

          });
      });
    }
  }

  return imageOptions;

}

function quillDefaultOptions() {
   // default options
   let options = {
    editorContainer : '',
    toolbarWrapper : '',
    placeholder : '',
    contents: '',
    theme : 'snow',
    bounds : document.body,
    toolbarOptions : {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{list: 'bullet'}, {list: 'ordered'}, {indent: '-1'}, {indent: '+1'}]
      ]
    }
  }
  return options;
}

window.initQuill = function initQuill(userOptions, textChangeCallback, imageUpload = false) {

  let imageOptions = imageUpload ? getQuillImageOptions() : {};

  let options = quillDefaultOptions();

  // User options
  if(userOptions) {
    $.each(userOptions, function(option, value){
      options[option] = value;
    });
  }

  let container = options.editorContainer;

  // only initialise if the container to load quill exists;
    if($(container).length) {

        let quill = new Quill(container, {
        modules: {
            toolbar: options.toolbarOptions,
            imageUploader: imageOptions,
            imageResize: {
              modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
            }
        },
        placeholder: options.placeholder,
        theme: options.theme,  // or 'bubble'
        bounds: options.bounds
        });

        let value = options.contents;
        let delta = quill.clipboard.convert(value);

        quill.setContents(delta, 'silent');

        if($(options.toolbarWrapper).length) {
            $(options.toolbarWrapper).html( $(container).prev(".ql-toolbar") );
        }

        quill.on('text-change', function(delta, oldDelta, source) {
            if (typeof textChangeCallback === 'function') {
                textChangeCallback();
            }
        });
        
        // $(document).on('click', options.editorContainer, function(){
        //   quill.focus();
        // });

        $(document).on('click', '.ql-picker-label', function(e){
          e.stopPropagation();
        });

        $(document).on('click', '.ql-tooltip-editor input', function(e){
          e.stopPropagation();
        });

    } // Endif container exists
}
