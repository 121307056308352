window.customAlert = function customAlert(options, confAction = '', disAction = '') {
  // Default Options
  var opts = {
    style : 'info',
    heading : 'Notice',
    type : 'alert',
    text : ''
  };

  // User options
  if(options) {
    $.each(options, function(option, value){
      opts[option] = value;
    });
  }

  // Validate Confirmation Action
  if( confAction.length && typeof confAction !== 'function' ) {
    opts.heading = 'Invalid Confirmation Action ';
    opts.text = 'The confirmation action must be a function';
    opts.style = 'danger';
  }

  // Validate Dismissal Action
  if(  disAction.length && typeof disAction !== 'function' ) {
    opts.heading = 'invalid Dismiss Action ';
    opts.text = 'The dismiss action must be a function';
    opts.style = 'danger';
  }

  $.ajax({
      url: '/confirmation-dialogue',
      type: 'GET',
      cache: false,
      data: opts,
      success: function(html) {
        // first remove the confirmation if it exists
        if( $('#confirmation-wrapper').length ) {
          $('#confirmation-wrapper').remove();
        }

        // Add new confirmation
        $('body').append(html);
        // Remove focus from any focused elements
        $('body input').trigger('blur');
          
        },
        error: function(error) {
            ajaxFailed(error);
            $('.loader-wrapper').fadeOut();
        }
  }).done(function(){

    $('#confirmation-confirm').on('click', function(){
      if(typeof confAction === 'function' ) {
        confAction();
      }
      $('#confirmation-wrapper').remove();
    });

    $('#confirmation-dismiss').on('click', function(){
      if( typeof disAction === 'function' ) {
        disAction();
      }
      $('#confirmation-wrapper').remove();
    });

  });
 
    
}
