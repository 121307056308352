import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-ui/ui/effects/effect-slide.js';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'select2/dist/js/select2.full.min.js';

require('./quill');
require('./bootstrap');
require('./helper-functions');
require('./currency-helper');
require('./customAlert');
require('./ajaxComplete');
require('./formCheckAjaxComplete');

import Popper from 'popper.js';
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = !(window.devicePixelRatio < 1.5 && /Win/.test(navigator.platform));

$.ajaxSetup({
  headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

$('form').on('focus', 'input[type=number]', function (e) {
  $(this).on('wheel.disableScroll', function (e) {
    e.preventDefault()
  })
})
$('form').on('blur', 'input[type=number]', function (e) {
  $(this).off('wheel.disableScroll')
})

$(document).ready(function(){
  $('.loader-wrapper').fadeOut();
  $('#floating-toolbar').draggable({ containment: "window"   });
});

$(document).on('click', '#floating-toolbar', function() {

  let toolbar = $('#designer-toolbar');
  let form = $('#designer-toolbar form');
  if ( $(toolbar).is(":visible") ) {
    $('#floating-toolbar').css('background-color', '#00c853');
    
    $(toolbar).slideUp();
  }
  else {
    $('#floating-toolbar').css('background-color', '#00b8d4');
    $(toolbar).slideDown();
    
    $(toolbar).css({
      "position": "absolute",
      "top": Math.max(0, (($(window).height() - $(form).outerHeight()) / 2) + $(window).scrollTop()) + "px",
      "left": Math.max(0, (($(window).width() - $(form).outerWidth()) / 2) +  $(window).scrollLeft()) + "px",
    });

    $('#designer-toolbar form').css({"border" : "1px solid #aaa"});
  }

});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

$( function() {
  $( ".datepicker" ).datepicker({
      dateFormat : 'yy-mm-dd'
  });
});

$(function () {
  // Initialize select2
  $('.fancy-select-required').select2({
    containerCssClass: 'required'
  });
  $('.fancy-select').select2();

});

$(document).on('click', '.ql-wrapper', function() {
  $(this).children('.quill-toolbar:not(.not-hidden)').slideDown(300);
});


$(document).on('click', function(event) {
  var target = $( event.target );

  if ( !$(target).hasClass('bem') ) {
    var tb = $('.quill-toolbar');
    $('.quill-toolbar:not(.not-hidden)').not( $(target).closest('.ql-wrapper').find(tb) ).slideUp(300);
  }
});

// Open modal in AJAX callback
$(document).on('click', '.has-popup', function(e) {
  e.preventDefault();
  this.blur(); // Manually remove focus from clicked link.
  $.get(this.href, function(html) {

    $('.modal').removeClass('last-modal');
    $('.modal-backdrop').removeClass('last-modal-backdrop');

    $(html).appendTo('body').modal();

    $(' .modal:not(.show)').addClass('last-modal');
    $(' .modal-backdrop:not(.show)').addClass('last-modal-backdrop');

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });

    $('.modal-dialog').draggable({
      handle: ".modal-header"
    });
    
  });

});

$(document).on('hidden.bs.modal', function (e) {
  $('.last-modal').remove();
  $('.last-modal-backdrop').remove();
});


$(document).on('click', 'a', function(e) {

    let link = $(this).attr('href');

    if( link && link.length && link.startsWith('#') ){
      e.preventDefault();
      $([document.documentElement, document.body]).animate({
          scrollTop: $(link).offset().top
      }, 500);
    }

});

window.ajaxFailed = function(data, status, response) {
  $('.loader-wrapper').fadeOut();
  if(data) {
    console.log({data, status, response});
    let ajaxRedirectStatuses = [
      401,
      403,
    ];

    if( (ajaxRedirectStatuses.includes(data.status) ) || response == 'Forbidden') {
        let options = {
          type : 'alert',
          style : 'warning',
          heading : 'Invalid session',
          text : "You've been logged out. You'll be redirected to the login screen."
        }
        customAlert(options, '', function(){
          window.location.href = '/login';
        });
        
    }

    else if(data.responseText) {
      let msg = JSON.parse(data.responseText).message;
      let errors = JSON.parse(data.responseText).errors;
      let msgs = '';

      $.each(errors, function(key, error) {
        $('*[name="' + key + '"]').addClass('is-invalid');
        msgs += ('<li>' + error[0] + '</li>');
      });

      if (errors) {
        let options = {
          type : 'alert',
          style : 'danger',
          heading : 'Errors Found!',
          text : '<ul>' + msgs + '</ul>'
        }
        customAlert(options);
      }
          
      else if(msg.length) {
        let options = {
          type : 'alert',
          style : 'danger',
          heading : 'Error.',
          text : msg
        }
        customAlert(options);
      }
      else {
        console.log(data);
      }
    }
  }
  $('.loader-wrapper').fadeOut();
}

require('./login');