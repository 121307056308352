$(document).ajaxComplete(function() {

  let createdFromDefault = $('#screatedfrom').val() ? $('#screatedfrom').val() : '+1w';
  let createdToDefault = $('#screatedto').val() ? $('#screatedfrom').val() : '+1w';
  let updatedFromDefault = $('#supdatedfrom').val() ? $('#supdatedfrom').val() : '+1w';
  let updatedToDefault = $('#supdatedto').val() ? $('#supdatedfrom').val() : '+1w';
  let thisFromDefault = $('#sthisfrom').val() ? $('#sthisfrom').val() : '+1w';
  let thisToDefault = $('#sthisto').val() ? $('#sthisfrom').val() : '+1w';
  let sentFromDefault = $('#ssentfrom').val() ? $('#ssentfrom').val() : '+1w';
  let sentToDefault = $('#ssentto').val() ? $('#ssentfrom').val() : '+1w';
  let nextFromDefault = $('#snextdatefrom').val() ? $('#snextdatefrom').val() : '+1w';
  let nextToDefault = $('#snextdateto').val() ? $('#snextdateto').val() : '+1w';

  $( ".datepicker" ).datepicker({
      dateFormat : 'yy-mm-dd'
  });

  $( function() {
    createdFrom = $( "#screatedfrom" )
      .datepicker({
        dateFormat : 'yy-mm-dd',
        defaultDate: createdFromDefault,
        changeMonth: true,
        numberOfMonths: 2
      })
      .on( "change", function() {
        createdTo.datepicker( "option", "minDate", getDate( this ) );
      }),
    createdTo = $( "#screatedto" ).datepicker({
      dateFormat : 'yy-mm-dd',
      defaultDate: createdToDefault,
      changeMonth: true,
      numberOfMonths: 2
    })
    .on( "change", function() {
      createdFrom.datepicker( "option", "maxDate", getDate( this ) );
    });

    updatedFrom = $( "#supdatedfrom" )
    .datepicker({
      dateFormat : 'yy-mm-dd',
      defaultDate: updatedFromDefault,
      changeMonth: true,
      numberOfMonths: 2
    })
    .on( "change", function() {
      createdTo.datepicker( "option", "minDate", getDate( this ) );
    }),
    updatedTo = $( "#supdatedto" ).datepicker({
      dateFormat : 'yy-mm-dd',
      defaultDate: updatedToDefault,
      changeMonth: true,
      numberOfMonths: 2
    })
    .on( "change", function() {
      updatedFrom.datepicker( "option", "maxDate", getDate( this ) );
    });

    thisFrom = $( "#sthisfrom" )
      .datepicker({
        dateFormat : 'yy-mm-dd',
        defaultDate: thisFromDefault,
        changeMonth: true,
        numberOfMonths: 2
      })
      .on( "change", function() {
        thisTo.datepicker( "option", "minDate", getDate( this ) );
      }),
      thisTo = $( "#sthisto" ).datepicker({
      dateFormat : 'yy-mm-dd',
      defaultDate: thisToDefault,
      changeMonth: true,
      numberOfMonths: 2
    })
    .on( "change", function() {
      thisFrom.datepicker( "option", "maxDate", getDate( this ) );
    });

    sentFrom = $( "#ssentfrom" )
      .datepicker({
        dateFormat : 'yy-mm-dd',
        defaultDate: sentFromDefault,
        changeMonth: true,
        numberOfMonths: 2
      })
      .on( "change", function() {
        sentTo.datepicker( "option", "minDate", getDate( this ) );
      }),
      sentTo = $( "#ssentto" ).datepicker({
      dateFormat : 'yy-mm-dd',
      defaultDate: sentToDefault,
      changeMonth: true,
      numberOfMonths: 2
    })
    .on( "change", function() {
      sentFrom.datepicker( "option", "maxDate", getDate( this ) );
    });

    nextDateFrom = $( "#snextdatefrom" )
    .datepicker({
      dateFormat : 'yy-mm-dd',
      defaultDate: nextFromDefault,
      changeMonth: true,
      numberOfMonths: 2
    })
    .on( "change", function() {
      nextDateTo.datepicker( "option", "minDate", getDate( this ) );
    }),
    nextDateTo = $( "#snextdateto" ).datepicker({
    dateFormat : 'yy-mm-dd',
    defaultDate: nextToDefault,
    changeMonth: true,
    numberOfMonths: 2
  })
  .on( "change", function() {
    nextDateFrom.datepicker( "option", "maxDate", getDate( this ) );
  });
  
  function getDate( element ) {
    let date;
    try {
      date = $.datepicker.parseDate( 'yy-mm-dd', element.value );
    } catch( error ) {
      date = null;
    }

    return date;
  }
});


  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  window.hideRemoveButton = function hideRemoveButton() {
    let rows = $('#items-table tbody tr');

    /*** Sorting of Line Items added in V 1.2.0  */
    $( "#items-table tbody" ).sortable(
      {
        cancel: ".one-row",
        handle: '.reorder-line-item-handle'
      }
    );

    if( !rows.length) {
        return;
    }
    else if (rows.length === 1) {
      $('.reorder-line-item-handle').addClass('handle-off');
      $( "#items-table tbody tr" ).addClass('one-row');
      $('.reorder-line-item').hide();
      $('.remove-line-item').hide();
      $('.reorder-line-item-handle, #items-table tbody tr').css('cursor', 'initial');


    }
    else {
      $( "#items-table tbody tr" ).removeClass('one-row');
        $('.reorder-line-item').show();
        $('.remove-line-item').show();
        $('.reorder-line-item-handle').removeClass('handle-off');
      $('.reorder-line-item-handle, #items-table tbody tr').css('cursor', 'all-scroll');

    }

  }
  
      
  $('.fancy-select-required').select2({
      containerCssClass: 'required'
    });
  $('.fancy-select').select2();

  hideRemoveButton();


  $('.delete-multi-all').on('change', function() {
    if($(this).is(':checked')) {
      $('.delete-multi-item').prop('checked', true).trigger('change');
    }
    else {
      $('.delete-multi-item').prop('checked', false).trigger('change');
    }
  });
  
  $('.delete-multi-item').on('change', function() {
    let btns = $('#delete-selected-items');
    let checkboxes = $('.delete-multi-item');
    let checkedItems = $('.delete-multi-item:checked');
  
    if(checkboxes.length === checkedItems.length) {
      $('.delete-multi-all').prop('checked', true);
    }
    else {
      $('.delete-multi-all').prop('checked', false);
    }
  
    if($(this).is(':checked') && !btns.length) {
      $('.main-items-table').after('<button id="delete-selected-items" class="btn btn-sm btn-danger ">Delete Selected Items</button>');
    }
  
    else if ( !$(this).is(':checked') && !checkedItems.length) {
      $('.delete-multi-all').prop('checked', false);
      $('#delete-selected-items').remove();
    }
    
  });
  
  if($('#show-table-actions').is(':checked')) {
    $('.all-table-actions-inner').show();
  }
  else {
      $('.all-table-actions-inner').hide();
  }

});
