$(document).on('change', '#show-register-toggle', function(e){
  e.preventDefault();

  if($('#show-register-toggle').prop('checked') === true) {

    let rightPos = $('#home-columns').width();
    $('#home-columns').animate({
      scrollLeft: rightPos
    }, 700);

    $('#homepage-wrapper').css({'background-color': '#000', 'transition': '0.7s'});
    $('.dot').css({'background-color': '#b80f0a', 'transition': '0.7s'});

    $('.home-page h3').html('Already have an account?');
    $('.home-page label').html('Login');
  }
  else {

    let leftPos = $('#home-columns').width();
    $('#home-columns').animate({
      scrollLeft: '-'+leftPos
    }, 700 );

    $('#homepage-wrapper').css({'background-color': '#b80f0a', 'transition': '0.7s'});
    $('.dot').css({'background-color': '#000', 'transition': '0.7s'});

    $('.home-page h3').html('Get Started for Free');
    $('.home-page label').html('Sign Up');
  }

})

$(document).ready(function(){
  if($('#show-register-toggle').prop('checked') === true) {

    let rightPos = $('#home-columns').width();
    $('#home-columns').animate({
      scrollLeft: rightPos
    }, 700);

    $('#homepage-wrapper').css({'background-color': '#000', 'transition': '0.7s'});
    $('.dot').css({'background-color': '#b80f0a', 'transition': '0.7s'});

    $('.home-page h3').html('Already have an account?');
    $('.home-page label').html('Login');
  }

});

$(document).on('submit', '#ajax-login-form, #ajax-register-form', function(e){
  e.preventDefault();
  let dest = $(this).prop('action');
  let data = $(this).serialize();

  $('.loader-wrapper').fadeIn();
  $.post(dest, data, function(res) {
    
    console.log(res);
    if (res == 1) {
      window.location.href = '/dashboard';
    }
    else {
      $('.loader-wrapper').fadeOut();
      ajaxFailed(res);
    }
  })
  .fail(function(data){
    $('.loader-wrapper').fadeOut();
    ajaxFailed(data);
  });
});