$(document).ajaxComplete(function () {

    // Check the form for changes
    var $documentForm = $('#document-form'),
        documentOriginalForm = $documentForm.serialize();

    $('#document-form :input').on('change input', function () {

        if ($documentForm.serialize() !== documentOriginalForm) {
            $('#save-doc').removeClass('btn-secondary').addClass('btn-success pulsate-fwd').prop('disabled', false);
            hasFormChanges = true;
        }
        else {
            hasFormChanges = false;
            $('#save-doc').removeClass('btn-success pulsate-fwd').addClass('btn-secondary').prop('disabled', true);
        }

    });

});