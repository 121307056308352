window.convertToSlug = function (text)
{
    return text
    .toLowerCase()
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'');
}

window.stringToColor = function(string) {

    let newString = string;
    if(!string.startsWith('#')) {
      newString = string;
    }
    else {
        let stringArr = string.split('');
        stringArr.shift();
        newString = stringArr.join('');
    }
  
    let output = newString;
    if(newString.length === 3) {
      let arr = newString.split('');
      
      output = arr[0] + arr[0] + arr[1] + arr[1] + arr[2] + arr[2];
    }

    if(output.length !== 6) {
      return '#000000';
    }
    return '#' + output;
}